<template>
    <div class="new-password">
        <div class="register-control-padding-r">
            <div class="register-control-left">
                <label class="control-label" for="auth-password">Password</label>

                <input
                        :class="{'has-error': (errorsForm.passwordInvalid)}"
                        :id="`auth-password`"
                        @input="passwordValidation"
                        class="form-control"
                        placeholder="Enter Password"
                        type="text"
                        v-if="showPasswordText"
                        v-model="credentials.password"
                />

                <input
                        :class="{'has-error': (errorsForm.passwordInvalid)}"
                        @input="passwordValidation"
                        class="form-control"
                        id="auth-password"
                        placeholder="Enter Password"
                        type="password"
                        v-else
                        v-model="credentials.password"
                />

                <span @click="showPasswordText = !showPasswordText" class="login-password-show login-form-item" v-if="isMobile">
                    <img
                            alt
                            class="hide-password"
                            src="../../../assets/img/auth/show.svg"
                            v-if="showPasswordText"
                    />
                    <img alt class="hide-password" src="../../../assets/img/auth/hide.svg" v-else/>
                </span>
                <label
                        class="text-danger small"
                        v-if="errorsForm.passwordInvalid && errorsForm.passwordConfirmInvalid"
                >
                    <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                    {{ errorsForm.password }}
                </label>
            </div>

            <div class="register-control-right">
                <label class="control-label" for="auth-password-confirm">Confirm</label>

                <input
                        :class="{'has-error': (errorsForm.passwordConfirmInvalid)}"
                        class="form-control"
                        id="`auth-password-confirm`"
                        placeholder="Confirm Password"
                        type="text"
                        v-if="showPasswordText"
                        v-model="credentials.password_confirmation"
                />

                <input
                        :class="{'has-error': (errorsForm.passwordConfirmInvalid)}"
                        class="form-control"
                        id="auth-password-confirm"
                        placeholder="Confirm Password"
                        type="password"
                        v-else
                        v-model="credentials.password_confirmation"
                />

                <label class="text-danger small" v-if="errorsForm.passwordConfirmInvalid">
                    <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                    {{ errorsForm.password_confirmation }}
                </label>
            </div>

            <span @click="showPasswordText = !showPasswordText" class="login-password-show" v-if="!isMobile">
                <img
                        alt
                        class="hide-password"
                        src="../../../assets/img/auth/show.svg"
                        v-if="showPasswordText"
                />
                <img alt class="hide-password" src="../../../assets/img/auth/hide.svg" v-else/>
            </span>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <label
                        class="text-danger small"
                        v-if="errorsForm.passwordInvalid && !errorsForm.passwordConfirmInvalid"
                >
                    <img alt class="warning-icon" src="../../../assets/img/auth/warning.svg"/>
                    {{ errorsForm.password }}
                </label>
            </div>
        </div>
        <div class="row register-control-padding-r incwarning">
            <label
                    class="col-xs-12 register-label"
                    v-if="!errorsForm.passwordInvalid && !errorsForm.passwordConfirmInvalid"
            >{{ errorsForm.password }}</label>

            <div class="col-xs-12" v-if="errorsForm.passwordInvalid">
                <password-checker :errors-form="errorsForm"></password-checker>
            </div>
        </div>
    </div>
</template>

<script>
    import PasswordChecker from "../../Settings/Items/PasswordChecker";

    export default {
        name: "NewPassword",
        components: {PasswordChecker},
        props: {
            credentials: Object,
            errorsForm: Object
        },
        data: () => ({
            showPasswordText: false,
            isMobile: false
        }),
        created() {
            this.mobileCheck();
        },
        methods: {
            passwordValidation() {
                window.vEvent.fire(
                    "password-validation",
                    this.credentials.password
                );
            },

            changePaswordType() {
                let bTmp = !showPasswordText;
                this.showPasswordText = bTmp;
            },

            mobileCheck() {
                let BC_onMobile = false;
                if (window.screen.availWidth <= 414 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    BC_onMobile = true;
                }

                this.isMobile = BC_onMobile;
            }
        }
    };
</script>

<style scoped>
    .two-passwds-with-eye {
        display: flow;
    }
</style>
