<template>
    <div
        class="hidden-xs register-right-area"
        id="RegisterRight"
        v-bind:class="mainClass"
    >
        <div class="form-group text-center">
            <img alt src="../../../assets/img/auth/register_right.svg"/>
            <div
                class="text-center introduce-label"
            >One account. All of {{ $store.state.siteName }} working for you.
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RegisterRight",
        props: {
            mainClass: Object
        }
    };
</script>

<style lang="less" scoped>
    .register-right-area {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 480px;

        &.right-min-height {
            min-height: 385px;
        }

        .introduce-label {
            width: 230px;//NEMESIS ADD
            margin-top: 15px;
            font-size: 16px;
            letter-spacing: 0;
        }
    }
</style>
