<template>
  <div class="auth-area account_cancelled" id="subscription">
    <!-- NEMESIS CHANGE <div class="auth-area" id="subscription" v-cloak>-->
    <auth-header :user="userInfo"></auth-header>

    <div class="login-area">
      <form @submit.prevent="registerSubscription" novalidate>
        <div class="subscription-form p-right-0">
          <div class="w100 subscription-body">
            <div class="subscription-left-side">
              

              <div class="subscription-left-body">
                <div class="introduce-logo">
                  <div class="auth-form-logo">
                    <img
                      alt="Bigcommand"
                      class="img-responsive"
                      src="../../assets/4kplayerlogo.svg"
                    />

<!--                    <img-->
<!--                      alt="Bigcommand"-->
<!--                      class="img-responsive"-->
<!--                      src="../../assets/img/auth/auth-logo.svg"-->
<!--                    />-->
                    BigCommand
                  </div>
                  <label class="intro-label">
                    Your 4KPlayer account has been suspended for non-payment.
                    <br />
                    <br />Please pay overdue invoice to reinstate your account.
                  </label>
                </div>

                <div class="subscription-left-div">
                  <div>Current Plan!: &nbsp;&nbsp;</div>
                  <div class="planType">{{ userInfo.plan.name }}</div>
                </div>

                <div class="subscription-left-div">Subscription Cycle</div>
                <div class="subscription-left-div flex-direction-column">
                  <el-radio v-model="plan_cost" label="1">
                    <template>Payments overdue</template>
                  </el-radio>
                  <div class="bill-info form-group">
                    <div class="form-group mt-2">
                      {{userInfo.plan.name}} Plan {{userInfo.plan.interval}} Billing:
                      <span
                        class="pull-right"
                      >${{userInfo.failed_invoice.plan_cost}}</span>
                    </div>
                    <div class="form-group">
                      Overage from current billing cycle:
                      <span
                        class="pull-right"
                      >${{userInfo.failed_invoice.overage}}</span>
                    </div>
                    <div class="form-group">
                      Credit from current billing cycle:
                      <span
                        class="pull-right credit"
                      >${{userInfo.failed_invoice.credit}}</span>
                    </div>
                    <div class="form-group">
                      Due today:
                      <span class="pull-right">${{userInfo.failed_invoice.total}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="subscription-right-side"
              :class="{'free-content': invoiceInfo.plan_id === 'free'}"
            >
              <div>
                <div class="subscription-right-header">Payment Method</div>

                <div class="subscription-right-body">
                  <div class="sel-payment-type">
                    <div
                      :class="{'active': invoiceInfo.payment_method === 'Stripe'}"
                      @click="invoiceInfo.payment_method = 'Stripe'"
                      class="sel-payment-info inline-flex">
                      <div class="paypment-img" v-if="!isMobile">
                        <img
                          alt
                          class="img-responsive"
                          src="../../assets/img/auth/credit-card-logo.svg"
                        />
                      </div>

                      <div class="block m-left-2">
                        <div style="display: flex">
                          <label class="payment-method-label">Credit Card</label>
                          <span
                            class="active-mark"
                            v-if="invoiceInfo.payment_method === 'Stripe'"
                          >
                            <img
                              alt
                              class="payment-icon"
                              src="../../assets/img/auth/payment-method-checked.svg"
                            />
                          </span>
                        </div>
                        <div>Visa, Mastercard, Amex, Discovery</div>
                      </div>
                    </div>

                    <div
                      v-if="usePaypal"
                      :class="{'active': invoiceInfo.payment_method === 'PayPal'}"
                      @click="invoiceInfo.payment_method = 'PayPal'"
                      class="sel-payment-info right-side inline-flex"
                    >
                      <div class="paypment-img" v-if="!isMobile">
                        <img alt class="img-responsive" src="../../assets/img/auth/paypal-logo.svg" />
                      </div>

                      <div class="block m-left-2">
                        <div style="display: flex">
                          <label class="payment-method-label">Paypal</label>
                          <div class="active-mark" v-if="invoiceInfo.payment_method === 'PayPal'">
                            <img
                              alt
                              class="payment-icon"
                              src="../../assets/img/auth/payment-method-checked.svg"
                            />
                          </div>
                        </div>
                        <div>Visa, Mastercard, Amex, Discovery</div>
                      </div>
                    </div>
                  </div>
                    <div class="subscription-error red" v-if="subscriptionError">
                      Failed: {{errorMessage}}
                  </div>
                  <div
                    v-if="usePaypal"
                    class="paypal"
                    v-show="invoiceInfo.payment_method === 'PayPal'"
                  >
                    <label class="control-label" for="paypal-email">Paypal Email</label>
                    <input
                      class="form-control"
                      id="paypal-email"
                      placeholder="Enter an email"
                      type="text"
                      v-model="paypalEmail"
                    />
                    <div id="paypal-container"></div>
                  </div>

              
                  <div v-else class="card-info" v-show="invoiceInfo.payment_method === 'Stripe'">
                    <label class="control-label" for="card-number">Card Number</label>

                    <input
                      class="form-control input-card-no"
                      id="card-number"
                      placeholder="Enter Card Number"
                      type="text"
                      @keyup="GetCardType"
                      v-model="invoiceInfo.card_number"
                    />
                    <div ref="card"></div>
                    <span class="card-icon blank" v-if="cardType==''">
                      <img
                        alt="Card"
                        class="img-responsive card-mark"
                        src="../../assets/img/auth/card/blank.svg"
                      />
                    </span>
                    <span class="card-icon visa" v-if="cardType=='Visa'">
                      <img
                        alt="Visa"
                        class="img-responsive card-mark"
                        src="../../assets/img/auth/visa_2x.svg"
                      />
                    </span>
                    <span class="card-icon master" v-if="cardType=='Mastercard'">
                      <img
                        alt="Master"
                        class="img-responsive card-mark"
                        src="../../assets/img/auth/card/mastercard.svg"
                      />
                    </span>
                    <span class="card-icon amex" v-if="cardType=='AMEX'">
                      <img
                        alt="Master"
                        class="img-responsive card-mark"
                        src="../../assets/img/auth/card/amex.svg"
                      />
                    </span>
                    <span class="card-icon discover" v-if="cardType=='Discover'">
                      <img
                        alt="Master"
                        class="img-responsive card-mark"
                        src="../../assets/img/auth/card/discover.svg"
                      />
                    </span>
                    <span
                      class="card-icon diner"
                      v-if="cardType=='Diners - Carte Blanche' || cardType=='Diners'"
                    >
                      <img
                        alt="Diner"
                        class="img-responsive card-mark"
                        src="../../assets/img/auth/card/diners.svg"
                      />
                    </span>

                    <span class="card-icon jcb" v-if="cardType=='JCB'">
                      <img
                        alt="Diner"
                        class="img-responsive card-mark"
                        src="../../assets/img/auth/card/jcb.svg"
                      />
                    </span>
                    <div class="card-desc">
                      <div class="expiration-date">
                        <label class="control-label">Expiration Date</label>
                        <div class="expiration-date-sub">
                          <div class="expiration-date-month">
                            <el-select
                              :value="invoiceInfo.exp_month"
                              class="group-select-left"
                              popper-class="custom_select_pop"
                              filterable
                              placeholder="Select Month"
                              v-model="invoiceInfo.exp_month"
                            >
                              <el-option
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-for="item in monthsObj"
                              ></el-option>
                            </el-select>
                          </div>
                          <span class="middle-line">
                            <span></span>
                          </span>
                          <div class="expiration-date-year">
                            <el-select
                              :value="invoiceInfo.exp_year"
                              class="group-select-right"
                              popper-class="custom_select_pop"
                              filterable
                              placeholder="Select Month"
                              v-model="invoiceInfo.exp_year"
                            >
                              <el-option
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                                v-for="item in yearsObj"
                              ></el-option>
                            </el-select>
                          </div>
                        </div>
                      </div>
                      <div class="CVV-info">
                        <label class="control-label" for="card-cvv">CVV</label>
                        <input
                          class="form-control input-cvv-info"
                          id="card-cvv"
                          placeholder="CVV"
                          type="text"
                          v-model="invoiceInfo.cvc"
                        />
                      </div>
                    </div>
                    <div
                      class="to-user-say"
                    >Your account will be reinstated and you will be billed ${{userInfo.failed_invoice.total}} today. Your videos and customers are still intact and will be restored to your account after you pay.</div>
                  </div>
                </div>

                <div>
                  <div>
                    <div class="form-group" v-if="invoiceInfo.payment_method === 'Stripe'">
                      <button class="btn paybutton" type="submit" :disabled="request_send">Pay Invoice & Reinstate Account</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <support-link></support-link>
      </form>
    </div>

    <!-- <auth-footer></auth-footer> -->
  </div>
</template>

<script>
import axios from "axios";
import AuthFooter from "./Items/AuthFooter";
import SupportLink from "./Items/SupportLink";
import AuthHeader from "./AuthHeader";
import AuthFormProgress from "./Items/AuthFormProgress";
import AuthFormHeader from "./Items/AuthFormHeader";
import SuspendedAlert from "./Items/SuspendedAlert";
import { mapMutations, mapState, mapActions } from "vuex";
import { find } from "lodash";
import createStripeToken from "../../utils/createStripeToken";
import { DEFAULT_PLAN_ID, INTERVALS } from "../../constants";
import { GET_BRAINTREE_CLIENT_TOKEN_API } from "../../../config/endpoints";
export default {
  data: () => ({
    cardType: "",
    userInfo: {plan:{},failed_invoice:{}},
    plan_cost: "1",
    usePaypal: false,
    request_send: false,
    invoiceInfo: {
      card_number: "",
      cvc: "",
      plan: "Free Forever",
      plan_id: "free",
      plan_cost: 0,
      plan_duration: "monthly",
      exp_month: new Date().getMonth(),
      exp_year: new Date().getFullYear(),
      user_email: "",
      user_name: "",
      user_id: "",
      invoice_id: "",
      payment_method: "Stripe",
      total:0
    },
    monthsObj: [],
    yearsObj: [],
    confirmEmail: false,
    isMobile: false,
    subscriptionError: false,
    errorMessage:''
  }),
  components: {
    AuthFormHeader,
    AuthFooter,
    AuthHeader
  },
  methods: {
    mobileCheck() {
      let BC_onMobile = false;
      if (
        window.screen.availWidth <= 414 ||
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        BC_onMobile = true;
      }

      this.isMobile = BC_onMobile;
    },
    getuserInfo() {
      this.axios({
        method: "get",
        url: "/cancelled_userinfo/" + this.$route.params.id
      }).then(response => {
        var res = response.data;
        if(res.type=='success'){
          this.userInfo = res.user
        }
        if(res.type=='error'){
          this.$router.push("/login");
        }

      }) .catch(e => {
          // todo change to notification, snotify, awn or some other
         this.$router.push("/login");
        });
    },
    GetCardType() {
      let number = this.invoiceInfo.card_number
        .split(" ")
        .filter(s => s)
        .join("");
      console.log(number);
      this.cardType = "";
      // visa
      let re = new RegExp("^4");
      if (number.match(re) != null) {
        this.cardType = "Visa";
      }

      // Mastercard
      // Updated for Mastercard 2017 BINs expansion
      if (
        /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
          number
        )
      ) {
        this.cardType = "Mastercard";
      }

      // AMEX
      re = new RegExp("^3[47]");
      if (number.match(re) != null) {
        this.cardType = "AMEX";
      }

      // Discover
      re = new RegExp(
        "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"
      );
      if (number.match(re) != null) {
        this.cardType = "Discover";
      }

      // Diners
      re = new RegExp("^36");
      if (number.match(re) != null) {
        this.cardType = "Diners";
      }

      // Diners - Carte Blanche
      re = new RegExp("^30[0-5]|38[0-5]");
      if (number.match(re) != null) {
        this.cardType = "Diners - Carte Blanche";
      }

      // JCB
      re = new RegExp("^35(2[89]|[3-8][0-9])");
      if (number.match(re) != null) {
        this.cardType = "JCB";
      }

      // Visa Electron
      re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
      if (number.match(re) != null) {
        this.cardType = "Visa Electron";
      }
    },
    registerSubscription() {
      this.invoiceInfo.plan = this.userInfo.plan.name;
      this.invoiceInfo.plan_id = this.userInfo.failed_invoice.stripe_plan;
      this.invoiceInfo.plan_cost = this.userInfo.failed_invoice.total;
      this.invoiceInfo.plan_duration = this.userInfo.plan.interval;
      this.invoiceInfo.total = this.userInfo.failed_invoice.total;
      this.invoiceInfo.invoice_id = this.userInfo.failed_invoice.stripe_id;
      this.invoiceInfo.user_id = this.userInfo.id;
      this.invoiceInfo.user_email = this.userInfo.email;
      console.log(this.invoiceInfo)
      this.request_send = true;
      createStripeToken(this.invoiceInfo)
        .then(tokenData => {
          this.axios.post( "/pay_pending_invoice/",
        {
              subscription: this.invoiceInfo,
              tokenData
            }
      ).then(response => {
        console.log(response)
        this.request_send = false;
        if(response.data.result!='error'){
        window.vEvent.fire("settings-common-success", {
                            title: "Payment Successfull!",
                            message: "Payment made successfully",
                            close: false
                        });
        this.$router.push("/login");}else{
          this.subscriptionError = true;
          this.errorMessage = response.data.error;
        }
      }) .catch(e => {
          // todo change to notification, snotify, awn or some other
          console.log(e);
          this.request_send = false;

          alert(e.error.message);
        });
        })
        .catch(e => {
          // todo change to notification, snotify, awn or some other
          console.log(e);
          this.request_send = false;

          alert(e.error.message);
        });
    }
  },

  created() {
    console.log(this.$route);
    for (let i = 0; i < 12; i++) {
      this.monthsObj[i] = {
        label: i + 1,
        value: i + 1
      };
    }

    let year = new Date().getFullYear();
    for (let y = 0; y < 10; y++) {
      this.yearsObj[y] = {
        label: year + y,
        value: year + y
      };
    }
    this.getuserInfo();
  }
};
</script>

<style scoped>
.credit {
  color: #0ad688;
}
.paybutton {
  background: #f27500 !important;
  border-radius: 20px;
  color: #fff;
}
.bill-info {
  margin-left: 40px;
}
.el-dropdown {
  font-size: 16px;
}
.el-radio {
  color: #21455e;
}
.subscription-left-div {
  /* margin-top: 70px !important; */
}
.subscription-form {
  /* border: none !important; */
  margin-bottom: 40px;
  margin-top: 280px;
  height: auto !important;
}
.card-desc {
  margin-bottom: 10px;
}
.to-user-say {
  margin-bottom: 10px;
  color: #21455e;
  line-height: 24px;
}
.intro-label {
  color: #f27500 !important;
}
.subscription-left-div >>> .el-radio__label {
  font-weight: normal;
}
.subscription-right-side {
  background: transparent !important;
}
.subscription-left-side {
  margin-left: 0 !important;
  padding-right: 10px;
}
.introduce-logo {
  border: none !important;
  height: auto !important;
  padding-left: 0px !important;
}
.subscription-right-side {
  padding-right: 0 !important;
}
.flex-direction-column {
  flex-direction: column;
}
.savings {
  color: #f27500;
  background: rgba(242, 117, 0, 0.1);
  border-radius: 12px;
  padding: 5px 14px;
  font-size: 13px;
  margin-left: 32px;
}
.middle-line {
  background: #f5f6f9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 20px;
}

.middle-line span {
  border: 0.6px solid #21455e;
  flex: 0 0 10px;
}
</style>

<!-- NEMESIS ADD START -->
<style lang="less">
.auth-form-logo {
  justify-content: center;
  align-items: flex-end;
  font-weight: 700;
  display: flex;
  align-items: flex-end;
  font-size: 20px;
  text-align: left;
  color: #21455e;
  margin-bottom: 30px;
}
#subscription {
  .el-input--suffix {
    &.is-focus {
      input {
        border-color: #00acdc;
      }
    }
  }
  input.el-input__inner {
    border: 2px solid #e2e5ed;
    box-shadow: none;
    font-size: 13px;
    /*height: 44px !important;
            line-height: 40px !important;*/
  }

  .group-select-left {
    input.el-input__inner {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  .group-select-right {
    input.el-input__inner {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  #paypal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  #braintree-paypal-loggedin {
    max-width: 100% !important;
    width: 100% !important;
  }

  .verification-body,
  .confirmation-body {
    margin-top: 50px;
  }
  .confirmation-body {
    /* Style for "Verificati" */
    width: 354px;
    height: 53px;
    color: #21455e;
    font-family: "Helvetica Neue";
    font-size: 21px;
    font-weight: 300;
  }
  .subscription-right-body .free-title {
    /* Style for "Confirm yo" */
    width: 167px;
    height: 16px;
    color: #21455e;
    font-family: "Helvetica Neue";
    font-size: 13px;
    font-weight: 500;
  }
  .subscription-right-body .free-email {
    /* Style for "Rectangle" */
    display: flex;
    align-items: center;
    width: 352px;
    height: 39px;
    border-radius: 4px;
    border: 1px solid #e2e5ed;
    background-color: #e3e0e0;
  }
  .subscription-right-body .free-email span {
    /* Style for "myaccounte" */
    width: 168px;
    height: 16px;
    color: #98a4b3;
    font-family: "Helvetica Neue";
    font-size: 13px;
    font-weight: 400;
    padding-left: 10px;
  }

  .subscription-right-body .free-links {
    padding-top: 23px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .subscription-right-body .free-links .verification {
    /* Style for "Rectangle" */
    display: inline-flex;
    width: 235px;
    height: 36px;
    border-radius: 18px;
    background-color: #0dabd8;
    cursor: pointer;
  }

  .subscription-right-body .free-links .verification span {
    /* Style for "Send my ve" */
    width: 196px;
    height: 20px;
    color: #ffffff;
    font-family: "Helvetica Neue";
    font-size: 16px;
    font-weight: 500;
  }

  .subscription-right-body .free-links a {
    /* Style for "Change my" */
    width: 99px;
    height: 16px;
    color: #21455e;
    font-family: "Helvetica Neue";
    font-size: 12px;
    font-weight: 300;
    /* Text style for "Change my" */
    text-decoration: underline;
  }
  .confirmation-part span.email {
    /* Style for "myaccounte" */
    width: 232px;
    height: 22px;
    color: #0ad688;
    font-family: "Helvetica Neue";
    font-size: 18px;
    font-weight: 400;
    cursor: pointer;
  }
  .confirmation-part div.icon {
    /* Style for "Rectangle" */
    width: 47px;
    height: 47px;
    border-radius: 29px;
    background-color: #0ad688;
  }
  .confirmation-part div.icon img {
    /* Style for "Rectangle" */
    width: 47px;
    height: 47px;
    border-radius: 29px;
    background-color: #0ad688;
  }
  .card-icon {
    width: 36px;
  }
  .card-icon.master {
    width: 41px;
    top: 41px;
    right: 25px;
  }
  .card-icon.discover {
    width: 50px;
    top: 41px;
    right: 25px;
  }
  .card-icon.jcb {
    width: 50px;
    top: 38px;
    right: 18px;
  }
  .card-icon.blank {
    width: 32px;
    top: 38px;
    right: 28px;
  }
  .card-icon.amex {
    width: 45px;
    top: 41px;
    right: 29px;
  }
  .card-icon.visa {
    width: 32px;
    top: 50px;
    right: 26px;
  }
  .card-icon.diner {
    width: 42px;
    top: 42px;
    right: 18px;
  }
}
  .subscription-error.red {
      color: red;
      margin-bottom: 10px;
  }
</style>
<!-- NEMESIS ADD END -->
<style lang="less" scoped src="../../assets/less/custom/auth.less"></style>
<style lang="less" src="../../assets/less/custom/pages.less"></style>