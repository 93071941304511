<template>
  <div>
    <reset-password v-if="user_token" :user_token="user_token" :resetBy="'user_token'"></reset-password>
  </div>
</template>

<script>
import ResetPassword from "./ResetPassword";
export default {
  components: { ResetPassword },
  data: () => ({
    user_token: null,
  }),
  created() {
    this.user_token = this.$route.params.token;
  },
};
</script>