<template>
    <div class="auth-password-group">
        <div
            :class="{'active': errorsForm.passwordType.uppercase}"
            class="form-inline auth-password-type"
        >
            <div class="auth-password-type-top">A</div>
            <div class="auth-password-type-bottom">One uppercase character</div>
        </div>

        <div
            :class="{'active': errorsForm.passwordType.lowercase}"
            class="form-inline auth-password-type"
        >
            <div class="auth-password-type-top">a</div>
            <div class="auth-password-type-bottom">One lowercase character</div>
        </div>

        <div
            :class="{'active': errorsForm.passwordType.number}"
            class="form-inline auth-password-type"
        >
            <div class="auth-password-type-top">7</div>
            <div class="auth-password-type-bottom">One number</div>
        </div>

        <div
            :class="{'active': errorsForm.passwordType.special}"
            class="form-inline auth-password-type"
        >
            <div class="auth-password-type-top">!</div>
            <div class="auth-password-type-bottom">One special character</div>
        </div>

        <div
            :class="{'active': errorsForm.passwordType.minimum}"
            class="form-inline auth-password-type"
        >
            <div class="auth-password-type-top">8&lt;</div>
            <div class="auth-password-type-bottom">8 characters minimum</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PasswordChecker",
        props: {
            errorsForm: Object
        },
        beforeDestroy() {
                        console.log('errosform before destroy',this.errorsForm)

            window.vEvent.stop("password-validation", this.passwordValidation);
        },
        mounted() {
            console.log('errosform mounted',this.errorsForm)
            window.vEvent.listen("password-validation", this.passwordValidation);
        },
        methods: {
            passwordValidation(password) {
                this.errorsForm.passwordType = {
                    uppercase: false,
                    lowercase: false,
                    number: false,
                    special: false,
                    minimum: false
                };

                let upper = /^(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;//NEMESIS CHANGE let upper = /^(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]+$/;
                if (upper.test((password))) {
                    this.errorsForm.passwordType.uppercase = true;
                }

                let lower = /^(?=.*[a-z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;//NEMESIS CHANGE let lower = /^(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]+$/;
                if (lower.test((password))) {
                    this.errorsForm.passwordType.lowercase = true;
                }

                let number = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;//NEMESIS CHANGE let number = /^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]+$/;
                if (number.test(String(password))) {
                    this.errorsForm.passwordType.number = true;
                }

                let special = /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;//NEMESIS CHANGE let special = /^(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/;
                if (special.test((password))) {
                    this.errorsForm.passwordType.special = true;
                }

                let minimum = /^(?=.{8,})[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/;//NEMESIS CHANGE let minimum = /^(?=.{8,})[a-zA-Z0-9!@#$%^&*]+$/;
                if (minimum.test((password))) {
                    this.errorsForm.passwordType.minimum = true;
                }
            }
        }
    };
</script>
